// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // production: false,
  // apiUrl: 'http://localhost:8000/',
  // whitelist: ['localhost:8000'],
  // blacklist: ['localhost:8000/api/v1/login', 'localhost:8000/api/v1/register']
  apiUrl: 'http://stvincentdepaulhospitalollur.com/',
  whitelist: ['stvincentdepaulhospitalollur.com'],
  blacklist: ['stvincentdepaulhospitalollur.com/api/v1/login', 'stvincentdepaulhospitalollur.com/api/v1/register']
};


// export const environment = {
//   production: true,
//   apiUrl: 'http://ec2-65-1-72-103.ap-south-1.compute.amazonaws.com/',
//   whitelist: ['ec2-65-1-72-103.ap-south-1.compute.amazonaws.com'],
//   blacklist: ['ec2-65-1-72-103.ap-south-1.compute.amazonaws.com/api/v1/login', 'ec2-65-1-72-103.ap-south-1.compute.amazonaws.com/api/v1/register']
// };

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
