import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public username: string;
	public rawdata : any;
	public password: string;
	public error: string;
  public role: string = "admin";

	constructor(private auth: AuthService, private router: Router) { }

	ngOnInit() {
	    if (this.auth.loggedIn) {
	      this.router.navigate(['']);
	    }
	}

	public submit() {
	  this.auth.login(this.username, this.password, this.role)
    	.pipe(first())
    	.subscribe(
    		(result) => {
    			this.rawdata =  result;
          console.log(result);
    			if(this.rawdata.status === "ok"){
            console.log(this.rawdata);
    				localStorage.setItem('access_token', result.token);
    				this.router.navigate(['home']);
    			}else{
            console.log("username password incorrect");
    				this.error = 'Either username or password is wrong'
    			}
    		},
    		err => this.error = 'Could not authenticate'
      	// result => this.router.navigate(['home']),
      	// err => this.error = 'Could not authenticate'
	  );
 	}
}