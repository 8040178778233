import { Component, OnInit, ViewChild } from '@angular/core';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditor, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { NewsService } from "../../../services/news/news.service";
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ncreate',
  templateUrl: './ncreate.component.html',
  styleUrls: ['./ncreate.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class NcreateComponent implements OnInit {

  public tools: object = {
    items: ['Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'SubScript', 'SuperScript', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink',
      'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  };

  public iframe: object = { enable: true };
  public height: number = 500;
  public heading: string;
  public image: string = '';
  public date: string;
  public snippet: string;
  public servicepointcreate: any;
  apiUrl: string = environment.apiUrl;
  photoUseInIcon: string;

  @ViewChild('alertMsg', null) rteObj: RichTextEditorComponent;
  @ViewChild("fileInput") fileInput;

  constructor(
    private NewsService: NewsService,
    private toastr: ToastrService,
    public router :Router
  ) { }

  ngOnInit() {

  }

  public createNews() {
    let rteValue: string = this.rteObj.value;
    this.NewsService.news_create(this.heading, this.snippet, this.image, rteValue, this.date).subscribe((response) => {
      this.servicepointcreate = response;
      if (this.servicepointcreate.status === "ok") {
        this.toastr.success('Success', this.servicepointcreate.message);
        this.router.navigate(['home/news/ndashboard'])
      }
    }, err => {
      this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
      this.toastr.error('Error', this.servicepointcreate.message);
    });
  }

  onChangeFile(): void {
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      console.log(fileToUpload);
      this.NewsService
        .uploadPostsImages(fileToUpload)
        .subscribe(res => {
          console.log(res);
          if (res.status == 'ok') {
            this.photoUseInIcon = this.apiUrl + res.data.image_location;
            console.log(this.photoUseInIcon);
            this.image = res.data.image_location;
          }
        });
    }
  }

}
