import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RouterModule, Routes } from "@angular/router";

import { LoginComponent } from "../../components/auth/login/login.component";
import { RegisterComponent } from "../../components/auth/register/register.component";
import { HomeComponent } from "../../components/home/home.component";
import { DashboardComponent } from "../../components/dashboard/dashboard.component";
import { NewsComponent } from "../../components/news/news/news.component";

import { NviewComponent } from "../../components/news/nview/nview.component";
import { NeditComponent } from "../../components/news/nedit/nedit.component";
import { NcreateComponent } from "../../components/news/ncreate/ncreate.component";

import { NgdashboardComponent } from "../../components/news/ngdashboard/ngdashboard.component";

import { NotfoundComponent } from "../../components/partials/notfound/notfound.component";
import { AuthGuard } from "../../guards/auth/auth.guard";
import { GdashboardComponent } from "../../components/gallery/gdashboard/gdashboard.component";
import { GalleryComponent } from "../../components/gallery/gallery/gallery.component";
import { GviewComponent } from "../../components/gallery/gview/gview.component";
import { GeditComponent } from "../../components/gallery/gedit/gedit.component";
import { GcreateComponent } from "../../components/gallery/gcreate/gcreate.component";
import { ProfilesComponent } from "../../components/profiles/profiles/profiles.component";
import { PdashboardComponent } from "../../components/profiles/pdashboard/pdashboard.component";
import { PeditComponent } from "../../components/profiles/pedit/pedit.component";
import { PcreateComponent } from "../../components/profiles/pcreate/pcreate.component";
import { PviewComponent } from "../../components/profiles/pview/pview.component";
import { ProfiletypeComponent } from "src/app/components/profiletype/profiletype/profiletype.component";
import { PtdashboardComponent } from "src/app/components/profiletype/ptdashboard/ptdashboard.component";
import { PtviewComponent } from "src/app/components/profiletype/ptview/ptview.component";
import { PteditComponent } from "src/app/components/profiletype/ptedit/ptedit.component";
import { PtcreateComponent } from "src/app/components/profiletype/ptcreate/ptcreate.component";
import { CircularComponent } from "src/app/components/circular/circular/circular.component";
import { CcreateComponent } from "src/app/components/circular/ccreate/ccreate.component";
import { CeditComponent } from "src/app/components/circular/cedit/cedit.component";
import { CviewComponent } from "src/app/components/circular/cview/cview.component";
import { CdashboardComponent } from "src/app/components/circular/cdashboard/cdashboard.component";
import { ContactComponent } from "src/app/components/contact/contact/contact.component";
import { CodashboardComponent } from "src/app/components/contact/codashboard/codashboard.component";
import { CoviewComponent } from "src/app/components/contact/coview/coview.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "register",
    component: RegisterComponent
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        canActivate: [AuthGuard],
        redirectTo: "dashboard"
      },
      {
        path: "dashboard",
        canActivate: [AuthGuard],
        component: DashboardComponent
      },
      {
        path: "news",
        canActivate: [AuthGuard],
        component: NewsComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            canActivate: [AuthGuard],
            redirectTo: "ndashboard"
          },
          {
            path: "ndashboard",
            canActivate: [AuthGuard],
            component: NgdashboardComponent
          },
          {
            path: "nview/:news_id",
            canActivate: [AuthGuard],
            component: NviewComponent
          },
          {
            path: "nedit/:news_id",
            canActivate: [AuthGuard],
            component: NeditComponent
          },
          {
            path: "ncreate",
            canActivate: [AuthGuard],
            component: NcreateComponent
          }
        ]
      },
      {
        path: "gallery",
        canActivate: [AuthGuard],
        component: GalleryComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            canActivate: [AuthGuard],
            redirectTo: "gdashboard"
          },
          {
            path: "gdashboard",
            canActivate: [AuthGuard],
            component: GdashboardComponent
          },
          {
            path: "gview/:gallery_id",
            canActivate: [AuthGuard],
            component: GviewComponent
          },
          {
            path: "gedit/:gallery_id",
            canActivate: [AuthGuard],
            component: GeditComponent
          },
          {
            path: "gcreate",
            canActivate: [AuthGuard],
            component: GcreateComponent
          }
        ]
      },

      {
        path: "profiles",
        canActivate: [AuthGuard],
        component: ProfilesComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            canActivate: [AuthGuard],
            redirectTo: "pdashboard"
          },
          {
            path: "pdashboard",
            canActivate: [AuthGuard],
            component: PdashboardComponent
          },
          {
            path: "pview/:profile_id",
            canActivate: [AuthGuard],
            component: PviewComponent
          },
          {
            path: "pedit/:profile_id",
            canActivate: [AuthGuard],
            component: PeditComponent
          },
          {
            path: "pcreate",
            canActivate: [AuthGuard],
            component: PcreateComponent
          }
        ]
      },

      {
        path: "profiletype",
        canActivate: [AuthGuard],
        component: ProfiletypeComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            canActivate: [AuthGuard],
            redirectTo: "ptdashboard"
          },
          {
            path: "ptdashboard",
            canActivate: [AuthGuard],
            component: PtdashboardComponent
          },
          {
            path: "ptview/:profiletype_id",
            canActivate: [AuthGuard],
            component: PtviewComponent
          },
          {
            path: "ptedit/:profiletype_id",
            canActivate: [AuthGuard],
            component: PteditComponent
          },
          {
            path: "ptcreate",
            canActivate: [AuthGuard],
            component: PtcreateComponent
          }
        ]
      },

      {
        path: "circular",
        canActivate: [AuthGuard],
        component: CircularComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            canActivate: [AuthGuard],
            redirectTo: "cdashboard"
          },
          {
            path: "cdashboard",
            canActivate: [AuthGuard],
            component: CdashboardComponent
          },
          {
            path: "cview/:circular_id",
            canActivate: [AuthGuard],
            component: CviewComponent
          },
          {
            path: "cedit/:circular_id",
            canActivate: [AuthGuard],
            component: CeditComponent
          },
          {
            path: "ccreate",
            canActivate: [AuthGuard],
            component: CcreateComponent
          }
        ]
      },

      {
        path: "contact",
        canActivate: [AuthGuard],
        component: ContactComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            canActivate: [AuthGuard],
            redirectTo: "codashboard"
          },
          {
            path: "codashboard",
            canActivate: [AuthGuard],
            component: CodashboardComponent
          },
          {
            path: "coview/:contact_id",
            canActivate: [AuthGuard],
            component: CoviewComponent
          },
        ]
      },

    ]
  },
  { path: "**", component: NotfoundComponent }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  declarations: []
})
export class RoutesModule { }
