import { Component, OnInit, ViewChild } from '@angular/core';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditor, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { CircularService } from "../../../services/circular/circular.service";
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-cview',
  templateUrl: './cview.component.html',
  styleUrls: ['./cview.component.css']
})
export class CviewComponent implements OnInit {

  circular_id: any;
  rawdata: any;
  apiUrl: string = environment.apiUrl;
  public heading: any;
  public image: any;
  public added_on: any;
  photoUseInIcon: string;

  constructor(
    private CircularService: CircularService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(
      params => {
        console.log(params.circular_id)
        this.circular_id = params.circular_id;
      });
  }

  ngOnInit() {
    this.getCircularDetails();
  }

  public getCircularDetails() {
    this.CircularService.circularShow(this.circular_id)
      .subscribe(
        (result) => {
          console.log(result);
          this.rawdata = result;
          if (this.rawdata.status === "ok") {
            this.heading = this.rawdata.data.circular_heading;
            this.image = this.rawdata.data.circular_image;
            this.added_on = moment(this.rawdata.data.circular_added_on).format('YYYY-MM-DD');
            this.photoUseInIcon = this.apiUrl + this.rawdata.data.circular_image;
          }
        });
  }

}
