import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';

import { SatPopoverModule } from '@ncstate/sat-popover';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ngx-toastr';
import { ObserversModule } from '@angular/cdk/observers';
// import { BarRatingModule } from "ngx-bar-rating";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//routing file
import { RoutesModule } from './modules/routes/routes.module';

//authentication files
import { AuthService } from './services/auth/auth.service';
import { AuthGuard } from './guards/auth/auth.guard';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { NavbarComponent } from './components/partials/navbar/navbar.component';
import { SidebarComponent } from './components/partials/sidebar/sidebar.component';
import { NotfoundComponent } from './components/partials/notfound/notfound.component';
import { HomeComponent } from './components/home/home.component';

import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatProgressBarModule,
  MatButtonModule,
  MatCheckboxModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatFormFieldModule,
  MatInputModule,
  MatTabsModule,
  MatDatepickerModule,
  MatNativeDateModule
} from '@angular/material';
import { DashboardComponent } from './components/dashboard/dashboard.component';

import { CheckElementExistInArrayPipe } from './pipes/checkelementexist/check-element-exist-in-array.pipe';

import { DateAgoPipe } from './pipes/dateago/date-ago.pipe';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
// import { AngularEditorModule} from '@kolkov/angular-editor';

import { httpInterceptorProviders } from './http-interceptors/index';

import { CheckelementnotexistPipe } from './pipes/checkelementnotexist/checkelementnotexist.pipe';
import { NewsComponent } from './components/news/news/news.component';
import { NgdashboardComponent } from './components/news/ngdashboard/ngdashboard.component';
import { NviewComponent } from './components/news/nview/nview.component';
import { NeditComponent } from './components/news/nedit/nedit.component';
import { NcreateComponent } from './components/news/ncreate/ncreate.component';
import { GdashboardComponent } from './components/gallery/gdashboard/gdashboard.component';
import { GalleryComponent } from './components/gallery/gallery/gallery.component';
import { GcreateComponent } from './components/gallery/gcreate/gcreate.component';
import { GeditComponent } from './components/gallery/gedit/gedit.component';
import { GviewComponent } from './components/gallery/gview/gview.component';
import { ProfilesComponent } from './components/profiles/profiles/profiles.component';
import { PcreateComponent } from './components/profiles/pcreate/pcreate.component';
import { PeditComponent } from './components/profiles/pedit/pedit.component';
import { PviewComponent } from './components/profiles/pview/pview.component';
import { PdashboardComponent } from './components/profiles/pdashboard/pdashboard.component';
import { ProfiletypeComponent } from './components/profiletype/profiletype/profiletype.component';
import { PtdashboardComponent } from './components/profiletype/ptdashboard/ptdashboard.component';
import { PtcreateComponent } from './components/profiletype/ptcreate/ptcreate.component';
import { PteditComponent } from './components/profiletype/ptedit/ptedit.component';
import { PtviewComponent } from './components/profiletype/ptview/ptview.component';
import { CircularComponent } from './components/circular/circular/circular.component';
import { CcreateComponent } from './components/circular/ccreate/ccreate.component';
import { CdashboardComponent } from './components/circular/cdashboard/cdashboard.component';
import { CeditComponent } from './components/circular/cedit/cedit.component';
import { CviewComponent } from './components/circular/cview/cview.component';
import { ContactComponent } from './components/contact/contact/contact.component';
import { CodashboardComponent } from './components/contact/codashboard/codashboard.component';
import { CoviewComponent } from './components/contact/coview/coview.component';


export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      return localStorage.getItem('access_token');
    },
    whitelistedDomains: environment.whitelist,
    blacklistedRoutes: environment.blacklist,
    headerName: 'Authorization'
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    NavbarComponent,
    SidebarComponent,
    NotfoundComponent,
    HomeComponent,
    DashboardComponent,

    CheckElementExistInArrayPipe,

    DateAgoPipe,

    // CeditComponent,
    CheckelementnotexistPipe,

    NewsComponent,

    NgdashboardComponent,

    NviewComponent,

    NeditComponent,

    NcreateComponent,

    GdashboardComponent,

    GalleryComponent,

    GcreateComponent,

    GeditComponent,

    GviewComponent,

    ProfilesComponent,

    PcreateComponent,

    PeditComponent,

    PviewComponent,

    PdashboardComponent,

    ProfiletypeComponent,

    PtdashboardComponent,

    PtcreateComponent,

    PteditComponent,

    PtviewComponent,

    CircularComponent,

    CcreateComponent,

    CdashboardComponent,

    CeditComponent,

    CviewComponent,

    ContactComponent,

    CodashboardComponent,

    CoviewComponent,

    // EarningsComponent,
    // EdashboardComponent,
    // ElastbookingsComponent,
  ],
  imports: [
    BrowserModule,
    RoutesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SatPopoverModule,
    
    ObserversModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,

    RichTextEditorModule,

    // AngularEditorModule,
    // BarRatingModule,
    NgbModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBpc206sVyHKgt1gggYNetswFBEn6HKWdI',
      libraries: ["places"]
    }),
    ToastrModule.forRoot() // ToastrModule added
  ],
  providers: [
    AuthService,
    AuthGuard,
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }