import { Component, OnInit, ViewChild } from '@angular/core';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { ProfilesService } from "../../../services/profiles/profiles.service";

@Component({
  selector: 'app-pcreate',
  templateUrl: './pcreate.component.html',
  styleUrls: ['./pcreate.component.css']
})
export class PcreateComponent implements OnInit {

  public full_name: string;
  public special_nomination: string;
  public present_address: string;
  public home_address: string;
  public mobileno: string;
  public parish: string;
  public diocese: string;
  public communication_email: string;
  public birthday: Date;
  public finalvows: Date;
  public ordination_day: Date;
  public image: string;
  public profilecreate: any;

  apiUrl: string = environment.apiUrl;
  photoUseInIcon: string;
  @ViewChild("fileInput") fileInput;

  servicepointlist: any[];
  selectedSource: string;

  constructor(
    private ProfilesService: ProfilesService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.fillSelectBoxes();
  }

  public fillSelectBoxes() {
    this.ProfilesService.user_type_list_for_select()
      .subscribe((response) => {
        console.log(response);
        this.servicepointlist = response;
        if (response.status === "ok") {
          this.servicepointlist = response.data;
          console.log(this.servicepointlist);
        } else {
          this.servicepointlist = [];
        }
      },
        err => {
          this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
        });
  }

  public createProfile() {

    this.ProfilesService.profile_create(
      this.selectedSource.toString(),
      this.full_name,
      this.special_nomination,
      this.present_address,
      this.home_address,
      this.mobileno,
      this.parish,
      this.diocese,
      this.communication_email,
      this.birthday,
      this.finalvows,
      this.ordination_day,
      this.image
    ).subscribe((response) => {
      this.profilecreate = response;
      if (this.profilecreate.status === "ok") {
        this.toastr.success('Success', this.profilecreate.message);
      } else {
        this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
        this.toastr.error('Error', this.profilecreate.message);
      }
    }, err => {
      this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
      this.toastr.error('Error', this.profilecreate.message);
    });
  }


  onChangeFile(): void {
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      console.log(fileToUpload);
      this.ProfilesService
        .uploadProfileImages(fileToUpload)
        .subscribe(res => {
          console.log(res);
          if (res.status == 'ok') {
            this.photoUseInIcon = this.apiUrl + res.data.image_location;
            console.log(this.photoUseInIcon);
            this.image = res.data.image_location;
          }
        });
    }
  }


}
