import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  apiUrl: string = environment.apiUrl;
  private newsServiceList = this.apiUrl + 'api/v1/galleryList';
  private uploadImageGallery = this.apiUrl + 'api/v1/insertImage';
  private galleryServiceCreate = this.apiUrl + 'api/v1/galleryStore';
  private galleryServiceView = this.apiUrl + 'api/v1/galleryShow';
  private galleryUpdate = this.apiUrl + 'api/v1/galleryUpdate';
  private galleryDelete = this.apiUrl + 'api/v1/galleryDestroy';

  constructor(private http: HttpClient) { }

  getgallerylist(sort: string, limit: number, order: string, page: number): Observable<any> {
    return this.http.post<any>(this.newsServiceList, { sort: sort, limit: limit, order: order, page: page })
      .pipe(
        map((data: any[]) => {
          console.log(data);
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      );
  }

  uploadGalleryImages(fileToUpload) {
    let input = new FormData();
    input.append("name", fileToUpload);
    input.append("folder_name", "gallery");
    return this.http.post<any>(this.uploadImageGallery, input);
  }


  gallery_create(image: string,): Observable<any> {
    let options = { image_location: image, folder_name: "gallery" };
    console.log(options);
    return this.http.post<any>(this.galleryServiceCreate, options)
      .pipe(
        map((data: any[]) => {
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      )
  }

  galleryShow(gallery_id: string): Observable<any> {
    return this.http.get<any>(this.galleryServiceView + '/' + gallery_id);
  }

  gallery_hide_update(status: number, gallery_id: string): Observable<any> {
    return this.http.post<any>(this.galleryUpdate + '/' + gallery_id,
      { hide: status }
    )
      .pipe(
        map((data: any[]) => {
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      )
  }

  gallery_delete(gallery_id: string): Observable<any> {
    return this.http.get<any>(this.galleryDelete + "/" + gallery_id)
      .pipe(
        map((data: any[]) => {
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      )
  }

}
