import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {


  apiUrl: string = environment.apiUrl;
  private contactServiceList = this.apiUrl + 'api/v1/contactList';
  private contactServiceView = this.apiUrl + 'api/v1/contactShow';
  private contactDelete = this.apiUrl + 'api/v1/contactDestroy';

  constructor(private http: HttpClient) { }

  contactGetlist(sort: string, limit: number, order: string, page: number, filter: string): Observable<any> {
    return this.http.post<any>(this.contactServiceList, { sort: sort, limit: limit, order: order, page: page, filter: filter })
      .pipe(
        map((data: any[]) => {
          console.log(data);
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      );
  }

  contactShow(news_id: string): Observable<any> {
    return this.http.get<any>(this.contactServiceView + '/' + news_id);
  }

  contact_delete(news_id: string): Observable<any> {
    return this.http.get<any>(this.contactDelete + "/" + news_id)
      .pipe(
        map((data: any[]) => {
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      )
  }
}
