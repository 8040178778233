import { Component, OnInit } from '@angular/core';
import { merge, Observable, of as observableOf } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { GalleryService } from "../../../services/gallery/gallery.service";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-gview',
  templateUrl: './gview.component.html',
  styleUrls: ['./gview.component.css']
})
export class GviewComponent implements OnInit {

  gallery_id: any;
  rawdata: any;
  apiUrl: string = environment.apiUrl;
  public image: any;
  photoUseInIcon: string;
  public hide: any;

  constructor(
    private GalleryService: GalleryService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(
      params => {
        console.log(params.gallery_id)
        this.gallery_id = params.gallery_id;
      });
  }

  ngOnInit() {
    this.getGalleryDetails();
  }

  public getGalleryDetails() {
    this.GalleryService.galleryShow(this.gallery_id)
      .subscribe(
        (result) => {
          console.log(result);
          this.rawdata = result;
          if (this.rawdata.status === "ok") {
            this.image = this.rawdata.data.gallery_image_location;
            this.photoUseInIcon = this.apiUrl + this.rawdata.data.gallery_image_location;
            this.hide = this.rawdata.data.gallery_hide;
          }
        });
  }

}
