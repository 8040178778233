/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenCheck } from './token-check';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [

  	{ provide: HTTP_INTERCEPTORS, useClass: TokenCheck, multi: true },

];