import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkElementExistInArray'
})
export class CheckElementExistInArrayPipe implements PipeTransform {
  transform(value: any, array: any): any {
  	console.log(value);
  	console.log(array);
  	// console.log(array.indexOf(value));
  	if(array){
  		return array.indexOf(value) > -1;
  	}else{
  		return false;
  	}
  }
}
