import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NewsService } from "../../../services/news/news.service";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-nview',
  templateUrl: './nview.component.html',
  styleUrls: ['./nview.component.css']
})
export class NviewComponent implements OnInit {

  news_id: any;
  rawdata: any;
  apiUrl: string = environment.apiUrl;
  public heading: any;
  public description: any;
  public image: any;
  public added_on: any;
  public snippet: any;
  photoUseInIcon: string;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private NewsService: NewsService,
  ) {
    this.route.params.subscribe(
      params => {
        console.log(params.news_id)
        this.news_id = params.news_id;
      });
  }

  ngOnInit() {
    this.getNewsDetails();
  }

  public getNewsDetails() {
    this.NewsService.newsShow(this.news_id)
      .subscribe(
        (result) => {
          console.log(result);
          this.rawdata = result;
          if (this.rawdata.status === "ok") {
            this.heading = this.rawdata.data.news_heading;
            this.description = this.rawdata.data.news_description;
            this.image = this.rawdata.data.news_image;
            this.added_on = this.rawdata.data.news_added_on;
            this.photoUseInIcon = this.apiUrl + this.rawdata.data.news_image;
            this.snippet = this.rawdata.data.news_snippet;
          }
        });
  }


}
