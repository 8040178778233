import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { ProfiletypeService } from "../../../services/profiletype/profiletype.service";

@Component({
  selector: 'app-ptview',
  templateUrl: './ptview.component.html',
  styleUrls: ['./ptview.component.css']
})
export class PtviewComponent implements OnInit {

  profiletype_id: any;
  rawdata: any;
  apiUrl: string = environment.apiUrl;
  public name: any;
  public order: any;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ProfiletypeService: ProfiletypeService,
  ) {
    this.route.params.subscribe(
      params => {
        console.log(params.profiletype_id)
        this.profiletype_id = params.profiletype_id;
      });
  }

  ngOnInit() {
    this.getProfileTypeDetails();
  }

  public getProfileTypeDetails() {
    this.ProfiletypeService.profileTypeShow(this.profiletype_id)
      .subscribe(
        (result) => {
          console.log(result);
          this.rawdata = result;
          if (this.rawdata.status === "ok") {
            this.name = this.rawdata.data.name;
            this.order = this.rawdata.data.order;
          }
        });
  }

}
