import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import 'rxjs/add/operator/catch';

import { Observable } from 'rxjs';

import { Router } from '@angular/router';

/** Pass untouched request through to the next request handler. */
@Injectable()

export class TokenCheck implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    // console.log(req);
    return next.handle(req).catch(err => {
      if (err.status === 401) {
        // window.location.href = "/login";
        localStorage.removeItem('access_token');
        this.router.navigate(['login']);
      }
      return Observable.throw(err);
    }) as any;
  }
}
