import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from "@angular/material";
import { catchError, map, startWith, switchMap, first } from "rxjs/operators";
import { merge, Observable, of as observableOf } from "rxjs";
import { ProfiletypeService } from "../../../services/profiletype/profiletype.service";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ptcreate',
  templateUrl: './ptcreate.component.html',
  styleUrls: ['./ptcreate.component.css']
})
export class PtcreateComponent implements OnInit {

  public ptcreate: any;
  public name: any;
  public order: any;

  constructor(
    private ProfiletypeService: ProfiletypeService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  public createProfileType() {
    this.ProfiletypeService.profile_type_create(this.name, this.order).subscribe((response) => {
      this.ptcreate = response;
      if (this.ptcreate.status === "ok") {
        this.toastr.success('Success', this.ptcreate.message);
      }
    }, err => {
      this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
      this.toastr.error('Error', this.ptcreate.message);
    });
  }

}
