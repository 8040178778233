import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class NewsService {

	apiUrl: string = environment.apiUrl;
	private newsServiceList = this.apiUrl + 'api/v1/newsList';
	private newsServiceCreate = this.apiUrl + 'api/v1/newsStore';
	private uploadImagePosts = this.apiUrl + 'api/v1/insertImage';
	private newsServiceView = this.apiUrl + 'api/v1/newsShow';
	private newsUpdate = this.apiUrl + 'api/v1/newsUpdate';
	private newsDelete = this.apiUrl + 'api/v1/newsDestroy';

	constructor(private http: HttpClient) { }

	getlist(sort: string, limit: number, order: string, page: number, filter: string): Observable<any> {
		return this.http.post<any>(this.newsServiceList, { sort: sort, limit: limit, order: order, page: page, filter: filter })
			.pipe(
				map((data: any[]) => {
					console.log(data);
					return data;
				}), catchError(error => {
					console.log(error);
					return throwError('Something went wrong!')
				})
			);
	}

	news_create(heading: string, snippet: string, image: string, description: string, added_on: string,): Observable<any> {
		let options = { heading: heading, snippet: snippet, description: description, added_on: added_on, image: image };
		console.log(options);
		return this.http.post<any>(this.newsServiceCreate, options)
			.pipe(
				map((data: any[]) => {
					return data;
				}), catchError(error => {
					console.log(error);
					return throwError('Something went wrong!')
				})
			)
	}

	uploadPostsImages(fileToUpload) {
		let input = new FormData();
		input.append("name", fileToUpload);
		input.append("folder_name", "news");
		return this.http.post<any>(this.uploadImagePosts, input);
	}

	newsShow(news_id: string): Observable<any> {
		return this.http.get<any>(this.newsServiceView + '/' + news_id);
	}

	news_update(data: any, news_id: string): Observable<any> {
		return this.http.post<any>(this.newsUpdate + '/' + news_id, data);
	}

	news_delete(news_id: string): Observable<any> {
		return this.http.get<any>(this.newsDelete + "/" + news_id)
			.pipe(
				map((data: any[]) => {
					return data;
				}), catchError(error => {
					console.log(error);
					return throwError('Something went wrong!')
				})
			)
	}
}
