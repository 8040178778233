import { Component, OnInit, ViewChild } from '@angular/core';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditor, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { CircularService } from "../../../services/circular/circular.service";
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';


@Component({
  selector: 'app-ccreate',
  templateUrl: './ccreate.component.html',
  styleUrls: ['./ccreate.component.css']
})
export class CcreateComponent implements OnInit {

  apiUrl: string = environment.apiUrl;
  public servicepointcreate: any;
  photoUseInIcon: string;
  public heading: string;
  public image: string = '';
  public date: string;

  @ViewChild("fileInput") fileInput;

  constructor(
    private CircularService: CircularService,
    private toastr: ToastrService,
    private router :Router
  ) { }

  ngOnInit() {

  }

  public createCircular() {
    this.CircularService.circular_create(this.heading, this.image, this.date).subscribe((response) => {
      this.servicepointcreate = response;
      if (this.servicepointcreate.status === "ok") {
        this.toastr.success('Success', this.servicepointcreate.message);
      }
    }, err => {
      this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
      this.toastr.error('Error', this.servicepointcreate.message);
      this.router.navigate(['home/circular/cdashboard'])

    });
  }

  onChangeFile(): void {
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      console.log(fileToUpload);
      this.CircularService
        .uploadCircularImages(fileToUpload)
        .subscribe(res => {
          console.log(res);
          if (res.status == 'ok') {
            this.photoUseInIcon = this.apiUrl + res.data.image_location;
            console.log(this.photoUseInIcon);
            this.image = res.data.image_location;
          }
        });
    }
  }
}
