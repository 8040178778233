import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkelementnotexist'
})
export class CheckelementnotexistPipe implements PipeTransform {
	transform(value: any, array: any): any {
	  	console.log(value);
	  	console.log(array);
	  	// console.log(array.indexOf(value));
	  	if(array){
	  		return array.indexOf(value) < 0;
	  	}else{
	  		return false;
	  	}
  	}
}
