import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CircularService {

  apiUrl: string = environment.apiUrl;
  private circularServiceList = this.apiUrl + 'api/v1/circularList';
  private circularServiceCreate = this.apiUrl + 'api/v1/circularStore';
  private uploadImageCircular = this.apiUrl + 'api/v1/insertImage';
  private circularServiceView = this.apiUrl + 'api/v1/circularShow';
  private circularUpdate = this.apiUrl + 'api/v1/circularUpdate';
  private circularDelete = this.apiUrl + 'api/v1/circularDestroy';

  constructor(private http: HttpClient) { }

  uploadCircularImages(fileToUpload) {
    let input = new FormData();
    input.append("name", fileToUpload);
    input.append("folder_name", "circulars");
    return this.http.post<any>(this.uploadImageCircular, input);
  }

  getCircularlist(sort: string, limit: number, order: string, page: number, filter: string): Observable<any> {
    return this.http.post<any>(this.circularServiceList, { sort: sort, limit: limit, order: order, page: page, filter: filter })
      .pipe(
        map((data: any[]) => {
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      );
  }

  circular_create(heading: string, image: string, added_on: string,): Observable<any> {
    let options = { heading: heading, added_on: added_on, image: image };
    return this.http.post<any>(this.circularServiceCreate, options)
      .pipe(
        map((data: any[]) => {
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      )
  }

  circularShow(circular_id: string): Observable<any> {
    return this.http.get<any>(this.circularServiceView + '/' + circular_id);
  }

  circular_update(data: any, circular_id: string): Observable<any> {
    return this.http.post<any>(this.circularUpdate + '/' + circular_id, data);
  }

  circular_delete(circular_id: string): Observable<any> {
    return this.http.get<any>(this.circularDelete + "/" + circular_id)
      .pipe(
        map((data: any[]) => {
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      )
  }

}
