import { Component, OnInit, ViewChild } from '@angular/core';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditor, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { CircularService } from "../../../services/circular/circular.service";
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-cedit',
  templateUrl: './cedit.component.html',
  styleUrls: ['./cedit.component.css']
})
export class CeditComponent implements OnInit {

  apiUrl: string = environment.apiUrl;
  public circularupdate: any;
  photoUseInIcon: string;
  public heading: string;
  public image: string = '';
  public date: string;
  circular_id: any;
  rawdata: any;

  @ViewChild("fileInput") fileInput;

  constructor(
    private CircularService: CircularService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe(
      params => {
        console.log(params.circular_id)
        this.circular_id = params.circular_id;
      });
  }

  ngOnInit() {
    this.getCircularDetails();
  }

  public getCircularDetails() {
    this.CircularService.circularShow(this.circular_id)
      .subscribe(
        (result) => {
          console.log(result);
          this.rawdata = result;
          if (this.rawdata.status == "ok") {
            this.heading = this.rawdata.data.circular_heading;
            this.image = this.rawdata.data.circular_image;
            // this.date = this.rawdata.data.circular_added_on;
            this.date = moment(this.rawdata.data.circular_added_on).format('YYYY-MM-DD');
            this.photoUseInIcon = this.apiUrl + this.rawdata.data.circular_image;
          }
        });
  }

  public updateCircular() {
    let data = { image: this.image, heading: this.heading, added_on: this.date };
    this.CircularService.circular_update(data, this.circular_id).subscribe((response) => {
      this.circularupdate = response;
      if (this.circularupdate.status === "ok") {
        this.toastr.success('Success', this.circularupdate.message);
        this.router.navigate(['home/circular/cdashboard'])

      }
    }, err => {
      this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
    });
  }

  onChangeFile(): void {
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      console.log(fileToUpload);
      this.CircularService
        .uploadCircularImages(fileToUpload)
        .subscribe(res => {
          console.log(res);
          if (res.status == 'ok') {
            this.photoUseInIcon = this.apiUrl + res.data.image_location;
            console.log(this.photoUseInIcon);
            this.image = res.data.image_location;
          }
        });
    }
  }

}
