import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { ProfiletypeService } from "../../../services/profiletype/profiletype.service";

@Component({
  selector: 'app-ptedit',
  templateUrl: './ptedit.component.html',
  styleUrls: ['./ptedit.component.css']
})
export class PteditComponent implements OnInit {

  profiletype_id: any;
  rawdata: any;
  ptupdate: any;
  apiUrl: string = environment.apiUrl;
  public name: any;
  public order: any;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ProfiletypeService: ProfiletypeService,
  ) {
    this.route.params.subscribe(
      params => {
        console.log(params.profiletype_id)
        this.profiletype_id = params.profiletype_id;
      });
  }

  ngOnInit() {
    this.getProfileTypeDetails();
  }

  public getProfileTypeDetails() {
    this.ProfiletypeService.profileTypeShow(this.profiletype_id)
      .subscribe(
        (result) => {
          console.log(result);
          this.rawdata = result;
          if (this.rawdata.status === "ok") {
            this.name = this.rawdata.data.name;
            this.order = this.rawdata.data.order;
          }
        });
  }

  public updateProfileType() {
    let data = { name: this.name, order: this.order }
    this.ProfiletypeService.profile_type_update(data, this.profiletype_id).subscribe((response) => {
      this.ptupdate = response;
      if (this.ptupdate.status === "ok") {
        this.toastr.success('Success', this.ptupdate.message);
      }
    }, err => {
      this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
      this.toastr.error('Error', this.ptupdate.message);
    });
  }
}
