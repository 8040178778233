import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  apiUrl: string = environment.apiUrl;
  private profileServiceList = this.apiUrl + 'api/v1/profileList';
  private profileServiceCreate = this.apiUrl + 'api/v1/profileStore';
  private uploadImageUsers = this.apiUrl + 'api/v1/insertImage';
  private profileServiceView = this.apiUrl + 'api/v1/profileView';
  private profileUpdate = this.apiUrl + 'api/v1/profileUpdate';
  private profileDelete = this.apiUrl + 'api/v1/profileDestroy';
  private profileTypeSelectList = this.apiUrl + 'api/v1/profileTypeSelectList';
  private basicDetails = this.apiUrl + 'api/v1/dashboardBasicDetails';

  constructor(private http: HttpClient) { }

  getprofilelist(sort: string, limit: number, order: string, page: number, filter: string): Observable<any> {
    return this.http.post<any>(this.profileServiceList, { sort: sort, limit: limit, order: order, page: page, filter: filter })
      .pipe(
        map((data: any[]) => {
          console.log(data);
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      );
  }

  profile_create(
    usertype_id: string,
    full_name: string,
    special_nomination: string,
    present_address: string,
    home_address: string,
    mobileno: string,
    parish: string,
    diocese: string,
    communication_email: string,
    birthday: Date,
    finalvows: Date,
    ordination_day: Date,
    image: string,
  ): Observable<any> {
    let options = {
      usertype_id: usertype_id,
      full_name,
      special_nomination,
      present_address,
      home_address,
      mobileno,
      parish,
      diocese,
      communication_email,
      birthday,
      finalvows,
      ordination_day,
      image
    };
    console.log(options);
    return this.http.post<any>(this.profileServiceCreate, options)
      .pipe(
        map((data: any[]) => {
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      )
  }

  uploadProfileImages(fileToUpload) {
    let input = new FormData();
    input.append("name", fileToUpload);
    input.append("folder_name", "users");
    return this.http.post<any>(this.uploadImageUsers, input);
  }

  profileShow(profile_id: string): Observable<any> {
    return this.http.get<any>(this.profileServiceView + '/' + profile_id);
  }

  profile_update(data: any, profile_id: string): Observable<any> {
    console.log(data);
    return this.http.post<any>(this.profileUpdate + '/' + profile_id, data);
  }

  profile_delete(profile_id: string): Observable<any> {
    return this.http.get<any>(this.profileDelete + "/" + profile_id)
      .pipe(
        map((data: any[]) => {
          console.log(data);
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      )
  }

  user_type_list_for_select(): Observable<any> {
    return this.http.get<any>(this.profileTypeSelectList)
      .pipe(
        map((data: any[]) => {
          console.log(data);
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      );
  }

  basic_details_fetch(): Observable<any> {
    return this.http.get<any>(this.basicDetails)
      .pipe(
        map((data: any[]) => {
          console.log(data);
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      );
  }

}
