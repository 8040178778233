import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfiletypeService {

  apiUrl: string = environment.apiUrl;
  private profileServiceList = this.apiUrl + 'api/v1/profileTypeList';
  private profileTypeDelete = this.apiUrl + 'api/v1/profileTypeDestroy';
  private profileTypeStore = this.apiUrl + 'api/v1/profileTypeStore';
  private profileTypeServiceView = this.apiUrl + 'api/v1/profileTypeShow';
  private profileTypeUpdate = this.apiUrl + 'api/v1/profileTypeUpdate';

  constructor(private http: HttpClient) { }

  getprofiletypelist(sort: string, limit: number, order: string, page: number): Observable<any> {
    return this.http.post<any>(this.profileServiceList, { sort: sort, limit: limit, order: order, page: page })
      .pipe(
        map((data: any[]) => {
          console.log(data);
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      );
  }

  profiletype_delete(profiletype_id: string): Observable<any> {
    return this.http.get<any>(this.profileTypeDelete + "/" + profiletype_id)
      .pipe(
        map((data: any[]) => {
          console.log(data);
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      )
  }

  profile_type_create(name: string, order: number): Observable<any> {
    let options = { name: name, order: order };
    console.log(options);
    return this.http.post<any>(this.profileTypeStore, options)
      .pipe(
        map((data: any[]) => {
          return data;
        }), catchError(error => {
          console.log(error);
          return throwError('Something went wrong!')
        })
      )
  }

  profileTypeShow(profiletype_id: string): Observable<any> {
    return this.http.get<any>(this.profileTypeServiceView + '/' + profiletype_id);
  }

  profile_type_update(data: any, profiletype_id: string): Observable<any> {
    console.log(data);
    return this.http.post<any>(this.profileTypeUpdate + '/' + profiletype_id, data);
  }

}
