import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MatPaginator, MatSort } from "@angular/material";
import { catchError, map, startWith, switchMap, first } from "rxjs/operators";
import { merge, Observable, of as observableOf } from "rxjs";
import { ProfilesService } from "../../../services/profiles/profiles.service";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pedit',
  templateUrl: './pedit.component.html',
  styleUrls: ['./pedit.component.css']
})
export class PeditComponent implements OnInit {

  profile_id: any;
  rawdata: any;
  apiUrl: string = environment.apiUrl;

  public full_name: string;
  public special_nomination: string;
  public present_address: string;
  public home_address: string;
  public mobileno: string;
  public parish: string;
  public diocese: string;
  public communication_email: string;
  public birthday: Date;
  public finalvows: Date;
  public ordination_day: Date;
  public image: string;
  public profilecreate: any;
  public photoUseInIcon: any;

  servicepointlist: any[];
  selectedSource: string;
  profileupdate: any;

  @ViewChild("fileInput") fileInput;

  constructor(
    private ProfilesService: ProfilesService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(
      params => {
        console.log(params.profile_id)
        this.profile_id = params.profile_id;
      });
  }

  ngOnInit() {
    this.fillSelectBoxes();
    this.getProfileDetails();
  }

  public fillSelectBoxes() {
    this.ProfilesService.user_type_list_for_select()
      .subscribe((response) => {
        console.log(response);
        this.servicepointlist = response;
        if (response.status === "ok") {
          this.servicepointlist = response.data;
          console.log(this.servicepointlist);
        } else {
          this.servicepointlist = [];
        }
      }, err => {
        this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
      });
  }

  public getProfileDetails() {
    this.ProfilesService.profileShow(this.profile_id)
      .subscribe(
        (result) => {
          console.log(result);
          this.rawdata = result;
          if (this.rawdata.status === "ok") {
            console.log(this.rawdata.data);
            this.full_name = this.rawdata.data.profile_full_name;
            this.special_nomination = this.rawdata.data.profile_special_nomination;
            this.present_address = this.rawdata.data.profile_present_address;
            this.home_address = this.rawdata.data.profile_home_address;
            this.mobileno = this.rawdata.data.profile_mobileno;
            this.parish = this.rawdata.data.profile_parish;
            this.diocese = this.rawdata.data.profile_diocese;
            this.communication_email = this.rawdata.data.profile_communication_email;
            this.birthday = this.rawdata.data.profile_birthday;
            this.finalvows = this.rawdata.data.profile_finalvows;
            this.ordination_day = this.rawdata.data.profile_ordination_day;
            this.image = this.rawdata.data.profile_image;
            this.photoUseInIcon = this.apiUrl + this.rawdata.data.profile_image;
            this.selectedSource = this.rawdata.data.profile_usertype_id;
          } else {
            this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
            this.toastr.error('Error', this.rawdata.message);
          }
        });
  }

  onChangeFile(): void {
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      console.log(fileToUpload);
      this.ProfilesService
        .uploadProfileImages(fileToUpload)
        .subscribe(res => {
          console.log(res);
          if (res.status == 'ok') {
            this.photoUseInIcon = this.apiUrl + res.data.image_location;
            console.log(this.photoUseInIcon);
            this.image = res.data.image_location;
          }
        });
    }
  }

  public updateProfile() {
    let data = {
      usertype_id: this.selectedSource.toString(),
      present_address: this.present_address,
      home_address: this.home_address,
      mobileno: this.mobileno,
      communication_email: this.communication_email,
      birthday: this.birthday,
      image: this.image,
    };
    if (this.finalvows) data['finalvows'] = this.finalvows;
    if (this.ordination_day) data['ordination_day'] = this.ordination_day;
    if (this.special_nomination) data['special_nomination'] = this.special_nomination;
    if (this.parish) data['parish'] = this.parish;
    if (this.diocese) data['diocese'] = this.diocese;
    if (this.full_name) data['full_name'] = this.full_name;
    this.ProfilesService.profile_update(data, this.profile_id).subscribe((response) => {
      console.log(response);
      this.profileupdate = response;
      if (this.profileupdate.status === "ok") {
        this.toastr.success('Success', this.profileupdate.message);
      }
    }, err => {
      this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
    });
  }

}
