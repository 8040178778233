import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  	constructor(public auth: AuthService, private router: Router) { }

  	ngOnInit() {

  	}

  	logout() {
		this.auth.logout()
	      .subscribe(
	        (result) => {
	          localStorage.removeItem('access_token');
	          this.router.navigate(['login']);
	        },
	        err => {
	          localStorage.removeItem('access_token');
	          this.router.navigate(['login']);
	        }
    	);
	}

}
