import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from "@angular/material";
import { catchError, map, startWith, switchMap, first } from "rxjs/operators";
import { merge, Observable, of as observableOf } from "rxjs";
import { NewsService } from "../../../services/news/news.service";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { CircularService } from "../../../services/circular/circular.service";

@Component({
  selector: 'app-cdashboard',
  templateUrl: './cdashboard.component.html',
  styleUrls: ['./cdashboard.component.css']
})
export class CdashboardComponent implements OnInit {

  displayedColumns: string[] = [
    "heading",
    "added_on",
    "created_at",
    "actions"
  ];

  data: any;
  bookingdata: any;
  error: any;
  pageIn: any;
  bookingpageIn: any;
  limit: any = 50;
  // bookingtype: string;

  sortName: string = "created_at";
  sortDirection: string = "desc";

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private CircularService: CircularService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.circularList();
  }

  public circularList(filter = '') {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.paginator.pageIndex == 0
            ? (this.pageIn = 1)
            : (this.pageIn = this.paginator.pageIndex + 1);
          this.sort.active
            ? (this.sortName = this.sort.active)
            : (this.sortName = "created_at");
          this.sort.direction
            ? (this.sortDirection = this.sort.direction)
            : (this.sortDirection = "desc");
          // return this.exampleDatabase!.listofUsers(this.sort.active, this.sort.direction, this.paginator.pageIndex, "all");
          return this.CircularService.getCircularlist(
            this.sortName,
            this.limit,
            this.sortDirection,
            this.pageIn,
            filter
          );
        }),
        map(data => {
          console.log(data);
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.data.result.total;
          return data.data.result.docs;
          // return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe(data => {
        console.log(data);
        this.data = data;
      });
  }

  applyFilter(filterValue: string) {
    this.pageIn = 1;
    this.sortDirection = 'desc';
    this.sortName = "created_at";
    this.circularList(filterValue.trim().toLowerCase());
  }


  deleteCircular(circular_id) {
    console.log(circular_id);
    var answer = confirm("Are you sure want to delete news?")
    if (answer) {
      this.CircularService.circular_delete(circular_id)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data.status && data.status == "ok") {
              this.toastr.success('Success', data.message);
              this.circularList();
            }
          },
          error => {
            this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
            this.circularList();
          }
        );
    }
  }

}
