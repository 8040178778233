import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  	apiUrl: string = environment.apiUrl;
  	private loginUrl = this.apiUrl + 'api/v1/login';
  	private logoutUrl = this.apiUrl + 'api/v1/logout';

  	constructor(private http: HttpClient) { 
  		console.log(this.apiUrl);  
  		console.log(this.loginUrl);
  	}

	login( username: string, password: string, role: string ): Observable<any> {
		console.log(username);
		console.log(password);
		console.log(this.apiUrl);
		console.log("this is the login url misture..");
		console.log(this.loginUrl);
	    return this.http.post<any>( this.loginUrl, { email: username, password: password, role: role })
	    .pipe(
	        map((data: any[]) => {
	             return data;
	           }), catchError( error => {
	           		console.log(error);
	             	return throwError( 'Something went wrong!' )
	        })
	    );
	}

  	logout() {
	    console.log(this.logoutUrl);
	    return this.http.post<any>( this.logoutUrl, { token: localStorage.getItem('access_token') })
	      .pipe(
	        map((data: any) => {
	          console.log(data);
	          return data;
	        }), catchError( error => {
	            return throwError(error)
	        })
	    );
  	}

  	public get loggedIn(): boolean {
  		return (localStorage.getItem('access_token') !== null);
  	}
}
