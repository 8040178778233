import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, first } from 'rxjs/operators';
import { ProfilesService } from "../../services/profiles/profiles.service";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import saveAs from 'file-saver';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  private rawdata: any;
  gallery_count: number = 0;
  profie_count: number = 0;
  news_count: number = 0;

  constructor(
    private auth: AuthService,
    private toastr: ToastrService,
    private ProfilesService: ProfilesService,
  ) {

  }

  ngOnInit() {
    this.getBasicDetails();
  }

  public getBasicDetails() {
    this.ProfilesService.basic_details_fetch()
      .subscribe(
        (result) => {
          console.log(result);
          this.rawdata = result;
          if (this.rawdata.status === "ok") {
            this.gallery_count = this.rawdata.data.gallery_count;
            this.profie_count = this.rawdata.data.profile_count;
            this.news_count = this.rawdata.data.news_count;
          }
        });
  }

}
