import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  	providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  	constructor(private router: Router) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

		const helper = new JwtHelperService();
		
	    if (localStorage.getItem('access_token')) {
	      	const decodedToken = helper.decodeToken(localStorage.getItem('access_token'));
			const expirationDate = helper.getTokenExpirationDate(localStorage.getItem('access_token'));
			const isExpired = helper.isTokenExpired(localStorage.getItem('access_token'));
			// if(decodedToken.roles.indexOf('admin') > -1){
			// 	// console.log(true);
			// 	return true;
			// }
			// console.log(expirationDate);
			// console.log(isExpired);
	      	return true;
	    }

	    this.router.navigate(['login']);
    	return false;
	}
}