import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from "@angular/material";
import { catchError, map, startWith, switchMap, first } from "rxjs/operators";
import { merge, Observable, of as observableOf } from "rxjs";
import { ProfilesService } from "../../../services/profiles/profiles.service";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-pdashboard',
  templateUrl: './pdashboard.component.html',
  styleUrls: ['./pdashboard.component.css']
})
export class PdashboardComponent implements OnInit {

  displayedColumns: string[] = [
    "image",
    "name",
    "created_at",
    "actions"
  ];

  data: any;
  bookingdata: any;
  error: any;
  pageIn: any;
  bookingpageIn: any;
  limit: any = 50;

  sortName: string = "created_at";
  sortDirection: string = "desc";
  apiUrl: string = environment.apiUrl;
  // bookingtype: string;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private ProfilesService: ProfilesService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.profileList();
  }

  public profileList(filter = '') {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.paginator.pageIndex == 0
            ? (this.pageIn = 1)
            : (this.pageIn = this.paginator.pageIndex + 1);
          this.sort.active
            ? (this.sortName = this.sort.active)
            : (this.sortName = "created_at");
          this.sort.direction
            ? (this.sortDirection = this.sort.direction)
            : (this.sortDirection = "desc");
          // return this.exampleDatabase!.listofUsers(this.sort.active, this.sort.direction, this.paginator.pageIndex, "all");
          return this.ProfilesService.getprofilelist(
            this.sortName,
            this.limit,
            this.sortDirection,
            this.pageIn,
            filter
          );
        }),
        map(data => {
          console.log(data);
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.data.result.total;
          return data.data.result.docs;
          // return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe(data => {
        console.log(data);
        this.data = data;
      });
  }

  applyFilter(filterValue: string) {
    this.pageIn = 1;
    this.sortDirection = 'desc';
    this.sortName = "created_at";
    this.profileList(filterValue.trim().toLowerCase());
  }

  deleteProfiles(profile_id) {
    var answer = confirm("Are you sure want to delete news?")
    if (answer) {
      this.ProfilesService.profile_delete(profile_id)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data && data.status && data.status == "ok") {
              this.toastr.success('Success', data.message);
              this.profileList();
            }
          },
          error => {
            this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
            this.profileList();
          }
        );
    }
  }

}
