import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MatPaginator, MatSort } from "@angular/material";
import { catchError, map, startWith, switchMap, first } from "rxjs/operators";
import { merge, Observable, of as observableOf } from "rxjs";
import { ProfilesService } from "../../../services/profiles/profiles.service";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pview',
  templateUrl: './pview.component.html',
  styleUrls: ['./pview.component.css']
})
export class PviewComponent implements OnInit {

  profile_id: any;
  rawdata: any;
  apiUrl: string = environment.apiUrl;

  public full_name: string;
  public special_nomination: string;
  public present_address: string;
  public home_address: string;
  public mobileno: string;
  public parish: string;
  public diocese: string;
  public communication_email: string;
  public birthday: Date;
  public finalvows: Date;
  public ordination_day: Date;
  public image: string;
  public profilecreate: any;
  public photoUseInIcon: any;

  servicepointlist: any[];
  selectedSource: string;

  constructor(
    private ProfilesService: ProfilesService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(
      params => {
        console.log(params.profile_id)
        this.profile_id = params.profile_id;
      });
  }

  ngOnInit() {
    this.fillSelectBoxes();
    this.getProfileDetails();
  }

  public fillSelectBoxes() {
    this.ProfilesService.user_type_list_for_select()
      .subscribe((response) => {
        console.log(response);
        this.servicepointlist = response;
        if (response.status === "ok") {
          this.servicepointlist = response.data;
          console.log(this.servicepointlist);
        } else {
          this.servicepointlist = [];
        }
      }, err => {
        this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
      });
  }

  public getProfileDetails() {
    this.ProfilesService.profileShow(this.profile_id)
      .subscribe(
        (result) => {
          console.log(result);
          this.rawdata = result;
          if (this.rawdata.status === "ok") {
            console.log(this.rawdata.data);
            this.full_name = this.rawdata.data.profile_full_name;
            this.special_nomination = this.rawdata.data.profile_special_nomination;
            this.present_address = this.rawdata.data.profile_present_address;
            this.home_address = this.rawdata.data.profile_home_address;
            this.mobileno = this.rawdata.data.profile_mobileno;
            this.parish = this.rawdata.data.profile_parish;
            this.diocese = this.rawdata.data.profile_diocese;
            this.communication_email = this.rawdata.data.profile_communication_email;
            this.birthday = this.rawdata.data.profile_birthday;
            this.finalvows = this.rawdata.data.profile_finalvows;
            this.ordination_day = this.rawdata.data.profile_ordination_day;
            this.image = this.rawdata.data.profile_image;
            this.photoUseInIcon = this.apiUrl + this.rawdata.data.profile_image;
            this.selectedSource = this.rawdata.data.profile_usertype_id;
          } else {
            this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
            this.toastr.error('Error', this.rawdata.message);
          }
        });
  }
}
