import { Component, OnInit, ViewChild } from '@angular/core';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditor, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { NewsService } from "../../../services/news/news.service";
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nedit',
  templateUrl: './nedit.component.html',
  styleUrls: ['./nedit.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class NeditComponent implements OnInit {

  public tools: object = {
    items: ['Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'SubScript', 'SuperScript', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink',
      'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  };

  public iframe: object = { enable: true };
  public height: number = 500;
  public heading: string;
  public image: string = '';
  public date: string;
  public servicepointcreate: any;
  public description: any;
  public added_on: any;
  public snippet: string;
  apiUrl: string = environment.apiUrl;
  photoUseInIcon: string;
  news_id: any;
  rawdata: any;
  servicepointupdate: any;

  @ViewChild('alertMsg', null) rteObj: RichTextEditorComponent;
  @ViewChild("fileInput") fileInput;

  constructor(
    private NewsService: NewsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router :Router

  ) {
    this.route.params.subscribe(
      params => {
        console.log(params.news_id)
        this.news_id = params.news_id;
      });
  }

  ngOnInit() {
    this.getNewsDetails();
  }

  public getNewsDetails() {
    this.NewsService.newsShow(this.news_id)
      .subscribe(
        (result) => {
          console.log(result);
          this.rawdata = result;
          if (this.rawdata.status === "ok") {
            this.heading = this.rawdata.data.news_heading;
            this.description = this.rawdata.data.news_description;
            this.rteObj.value = this.rawdata.data.news_description;;
            this.image = this.rawdata.data.news_image;
            this.added_on = this.rawdata.data.news_added_on;
            this.snippet = this.rawdata.data.news_snippet;
            this.added_on = moment(this.rawdata.data.news_added_on).format('YYYY-MM-DD');
            console.log(this.added_on);
            this.photoUseInIcon = this.apiUrl + this.rawdata.data.news_image;
          }
        });
  }

  public updateNews() {
    let rteValue: string = this.rteObj.value;
    let data = { description: rteValue, image: this.image, heading: this.heading, added_on: this.added_on, snippet: this.snippet };
    this.NewsService.news_update(data, this.news_id).subscribe((response) => {
      this.servicepointupdate = response;
      if (this.servicepointupdate.status === "ok") {
        this.toastr.success('Success', this.servicepointupdate.message);
        this.router.navigate(['home/news/ndashboard'])

      }
    }, err => {
      this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
    });
  }

  onChangeFile(): void {
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      console.log(fileToUpload);
      this.NewsService
        .uploadPostsImages(fileToUpload)
        .subscribe(res => {
          console.log(res);
          if (res.status == 'ok') {
            this.photoUseInIcon = this.apiUrl + res.data.image_location;
            console.log(this.photoUseInIcon);
            this.image = res.data.image_location;
          }
        });
    }
  }

}
