import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NewsService } from "../../../services/news/news.service";
import { environment } from '../../../../environments/environment';
import { ContactService } from "../../../services/contact/contact.service";

@Component({
  selector: 'app-coview',
  templateUrl: './coview.component.html',
  styleUrls: ['./coview.component.css']
})
export class CoviewComponent implements OnInit {

  contact_id: any;
  rawdata: any;
  apiUrl: string = environment.apiUrl;
  public name: any;
  public email: any;
  public message: any;
  public created_at: any;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ContactService: ContactService,
  ) {
    this.route.params.subscribe(
      params => {
        console.log(params.contact_id)
        this.contact_id = params.contact_id;
      });
  }

  ngOnInit() {
    this.getContactDetails();
  }

  public getContactDetails() {
    this.ContactService.contactShow(this.contact_id)
      .subscribe(
        (result) => {
          console.log(result);
          this.rawdata = result;
          if (this.rawdata.status === "ok") {
            this.name = this.rawdata.data.contact_name;
            this.email = this.rawdata.data.contact_email;
            this.message = this.rawdata.data.contact_message;
            this.created_at = this.rawdata.data.contact_created_at;
          }
        });
  }

}
